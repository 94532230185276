import React from 'react'
import './Schedules.css'
import Navbar from '../../components/NavigationBar';
import Footer from '../../components/Footer';
function Schedules() {
  return (
    <div>
        <Navbar/>
      Schedules
      <Footer/>
    </div>
  )
}

export default Schedules;
